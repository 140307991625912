<template>
    
    <div class="container">
        <div class="logo">
            <img src="http://img202.yun300.cn/img/logo1.png?tenantId=221484&viewType=1&k=1625485789000" alt="">
        </div>

        <div class="right">
            
            <span><b>{{language}}</b></span><button @click="changeChinese"><b>{{cn}}</b></button>  /  <button @click="changeEnglish"><b>{{en}}</b></button>
            
        </div>
   
    
    <div style="clear:both"></div>
    </div>
  
</template>

<script>
export default {
    computed: {
        language() {
            return this.$store.state.cn ? "语言: " : "Language: " 
        },
        cn() {
            return this.$store.state.cn ? "中文" : "CN" 
        },
        en() {
            return this.$store.state.cn ? "English" : "EN" 
        },
    },
    methods: {
        changeChinese(){
            if(this.$store.state.cn) return;
            this.$store.state.cn = true;
            this.$router.push({ name: 'Home' })
        },
        changeEnglish(){
            if(!this.$store.state.cn) return;
            this.$store.state.cn = false;
            this.$router.push({ name: 'HomeEN' })
        }
    },

}
</script>

<style scoped>

.container{
    justify-content: flex-end;
}


.logo > img{
    float: left;
    width: 50%;
    min-width: 300px;
}

.right{
    margin-top: 5%;
    margin-bottom: 10px;
    float: right;
}

.chinese, .english{
    width: 20px;
    cursor: pointer;
}

</style>