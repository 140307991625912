<template>
  <div>
      <div class="hero">
            <img src="../assets/contact.jpg" alt="">
            <img src="../assets/contact2.jpg" alt="" class="title">
       </div>

        <div class="content-container">

             <div v-if="!this.$store.state.cn" class="about-content">
            <p>Shanghai Qinghao plastic tray Manufacturing Co., Ltd</p>
             <p>(Shanghai Qing-ze plastic tray Manufacturing Co., Ltd.)</p>
             <p>Address：Caojing Town, Jinshan District, Shanghai to build a road 138</p>
             <p>Postcode：201507</p>
             <p>Fax：021-57254057</p></br>
             <p>General Manager Shen Wei:</p>
             <p>Mobile：13611631598</p>
             <p>WeChat: 13611631598</p>
             <p>E-mail：shenyicen_yoki@sina.com</p>
       </div>

       <div v-if="this.$store.state.cn" class="about-content">
            <p>上海庆豪塑料托盘制造有限公司</p>
            <p>（上海庆泽塑料托盘制造有限公司）</p>
            <p>地址：上海市金山区漕泾镇共建路138号</p>
            <p>邮编：201507</p>
            <p>传真：021-57254057</p></br>
            <p>销售部经理沈伟:</p>
            <p>电话：13611631598</p>
            <p>微信：13611631598</p>
            <p>网址：http://www.shqhsltp.com</p>
            <p>E-mail：shenyicen_yoki@sina.com</p>
       </div>
        </div>
      

       
  </div>
</template>

<script>
export default {

}
</script>

<style>
.hero img,.equipment{
    width: 100%;
}
.hero .title{
    width: 26em;
}

.content-container{
    margin-top: 2em;
}

.about-content{
    width: 100%;
    margin: 1em auto;

}

.about-content > p{
    padding-left: 23vw;
}
</style>