<template>
    <div>
        <div class="container" @click="showDetails">
            <img :src="pallet.img" alt="">
            <p>{{pallet.model}}</p>
        </div>
    </div>
    
  
</template>

<script>
export default {

    props:['pallet'],

    methods: {
        showDetails() {
            this.$emit("showDetails",this.pallet)
        }
    },

}
</script>

<style scoped>
    .container:hover > img {
        border: 1px solid rgb(201, 118, 24);
    }

    img{
        border: 1px solid grey;
        width: 100%;
    }

    p{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
    }
</style>