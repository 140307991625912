import Vue from "vue";
import VueRouter from "vue-router";
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Product from '../views/Product.vue';
import Applications from '../views/Applications.vue';
import ProductSearch from '../views/ProductSearch.vue';
import Announcement from '../views/Announcement.vue';
import Contact from '../views/Contact.vue';
import Qualifications from '../views/Qualifications.vue';


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/en',
    name: 'HomeEN',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/en/about',
    name: 'AboutEN',
    component: About
  },
  {
    path: '/product',
    name: 'Product',
    component: Product,
  },
  {
    path: '/en/product',
    name: 'ProductEN',
    component: Product
  },
  {
    path: '/search',
    name: 'ProductSearch',
    component: ProductSearch,
  },
  {
    path: '/en/search',
    name: 'ProductSearchEN',
    component: ProductSearch
  },
  {
    path: '/applications',
    name: 'Applications',
    component: Applications,
  },
  {
    path: '/en/applications',
    name: 'ApplicationsEN',
    component: Applications
  },
  {
    path: '/qualifications',
    name: 'Qualifications',
    component: Qualifications,
  },
  {
    path: '/en/qualifications',
    name: 'QualificationsEN',
    component: Qualifications
  },
  {
    path: '/announcement',
    name: 'Announcement',
    component: Announcement,
  },
  {
    path: '/en/announcement',
    name: 'AnnouncementEN',
    component: Announcement
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/en/contact',
    name: 'ContactEN',
    component: Contact
  },
]

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
