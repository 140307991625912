<template>

     <div>
        <div class="hero">
            <img src="../assets/product.jpg" alt="">
            <img src="../assets/product2.jpg" alt="" class="title">
        </div>

       
        <SelectButtons :buttons="buttons" @select="changeSeleced" />

       

        <div v-if="!detail">
            <div class="pallet"  v-for="pallet in showPallets" :key="pallet.id">
                <Pallet :pallet="pallet" @showDetails="showDetails"/>
            </div>  
        </div>

       

        <PalletDetails v-if="detail" :pallet="selectedPallet" @back="back"/>

        <div class="row" v-if="!detail">
            <button class="pagination-button-previous" @click="changePage(currentPage - 1)" :class="{disable : currentPage == 1}">{{ previous}}</button>
            <span v-for="(item,index) in Array(totalPage)" :key="(index)" >
                <button class="pagination-button" @click="changePage(index + 1)" :class="{select: currentPage == index + 1}">{{index + 1}}</button>
            </span>
            <button class="pagination-button-next" @click="changePage(currentPage + 1)" :class="{disable : currentPage == totalPage}">{{ next}}</button>
        </div>

    </div>
   


</template>

<script>
import SelectButtons from '@/components/SelectButtons.vue';
import Pallet from '@/components/Pallet.vue';
import PalletDetails from '@/components/PalletDetails.vue';
import axios from "axios";

export default {
    components: {
        Pallet,SelectButtons,PalletDetails
    },
    data() {
        return {
            buttons: [{
                cn : "标准系列",
                en : "Standard Series",
                name : "standard"
            },
            {
                cn : "货架系列",
                en : "Shelf Series",
                name : "goods"
            },
            {
                cn : "超轻系列",
                en : "Ultralight Series",
                name : "light"
            }],
            selected : "standard",
            totalPallets : [],
            showPallets : [],
            totalPage : 0,

            detail : false,
            componentKey : 0,
            currentPage : 1
        }
    },
    mounted(){
        this.changeSeleced(this.selected)
    },
    methods: {
        changeSeleced(name) {
            console.log(name)
            this.selected = name;
            this.detail = false;
            this.currentPage = 1
            var url
            if(this.$store.state.cn){
                url = "http://47.106.137.238:3001/pallet?category=" + this.selected + "&language=cn"
            }else{
                url = "http://47.106.137.238:3001/pallet?category=" + this.selected + "&language=en"
            }
            axios.get(url).then((response) => {
                this.totalPallets = response.data
                this.totalPage = Math.ceil(this.totalPallets.length / 10);

                const begin = ((this.currentPage - 1) * 10)
                const end = begin + 10
                this.showPallets = this.totalPallets.slice(begin,end)

            })   
        },
        showDetails(pallet){
            this.selectedPallet = pallet;
            this.detail = true;
        },
        changePage(pageNumber){
            if(pageNumber < 1 || pageNumber > this.totalPage) return;
            this.currentPage = pageNumber;

            const begin = ((this.currentPage - 1) * 8)
            const end = begin + 8
            this.showPallets = this.totalPallets.slice(begin,end)
        },
        back(){
            this.detail = false;
        }
    },
    computed: {
        previous(){
            if(this.$store.state.cn){
                return "上一页"
            }
            return "Previous"
        },
        next(){
            if(this.$store.state.cn){
                return "下一页"
            }
            return "Next"
        }
    },
    
}
</script>

<style scoped>
    .mid-img{
        margin-top: 3em;
        text-align: center;
    }

    .button-container{
        margin-top: 1em;
        display: flex;
        flex-wrap: wrap;
        justify-content: center; /*控制左右*/
    }

    .line{
        height: 2px;
        background-color: #0079b9;
        position: relative;
        bottom: 2px;
    }

    .collection {
        padding: 0.5em 1.3em;
        margin: 0.8em;
        margin-bottom: 0px;
        background-color: #0079b9;
        color: white;
        cursor: pointer;
        z-index: 100;
        font-weight: bold;
    }

    .select{
        background-color: rgb(11, 46, 99);
        color: white;
    }

    .pallet{
        width: 11em;
        height: 10em;
        margin: 1em;
        display: inline-block;
        cursor: pointer;
    }

    a{
	text-decoration: none;
    }

    .row{
        margin-top: 0.5em;
        text-align: center;
    }

    .pagination-button{
        padding: 7px;
        margin: 2px;
        font-size: 1em;
        cursor: pointer;
        border: 1px solid grey;
    }

    .pagination-button-previous,.pagination-button-next{
        padding: 7px;
        margin: 3px;
        margin-top: 5px;
        font-size: 0.9em;
        cursor: pointer;
        border: 1px solid grey;
        border-radius: 2px;
    }

    .hero img {
        width: 100%;
    }
    .hero .title{
        width: 25em;
    }

    .disable{
        cursor: not-allowed;
        background: lightgray;
        color: white;
    }

</style>