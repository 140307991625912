<template>
  <div>
      <div class="title">
        {{article.title}}
      </div>

      <div class="date">
          {{article.date}}
      </div>

      <div class="hero">
          <img :src="article.hero" alt="">
      </div>

      <div class="content">
          <div v-for="(paragraph,index) in article.paragraphs" :key="index">
              <div class="paragraph">
                  {{paragraph}}
              </div>
          </div>
      </div>

      <button class="back" @click="goBack">{{ back }}</button>

  </div>
</template>

<script>
export default {
    props: {
        article: {
            type: Object,
        },
    },
    methods: {
        goBack() {
            this.$emit('back')
        }
    },
    computed: {
        back() {
            if(this.$store.state.cn){
                return "返回"
            }else{
                return "Back"
            }
        }
    },
}
</script>

<style scoped>

.title{
    font: bolder;
    font-size: 1.5em;
    text-align: center;
    border-top: #0079b9 1px solid;
    padding-top: 1em;
}

.date{
    text-align: right;
    margin-right: 2em;
}


.hero > img{
    max-width: 80%;
    margin: 10px auto;
}

.content{
    margin: 10px 0.5em;
}

.paragraph{
    TEXT-INDENT: 2em;
    LETTER-SPACING: 0em;
    LINE-HEIGHT: 2em;
    text-align: left;
    color: rgb(71, 71, 71);
}

.back{
        background: rgb(38, 38, 114);
        color: white;
        width: 30%;
        height: 30px;
        border-radius: 1em;
        display: block;
        margin: 2em auto;
        cursor: pointer;
}


</style>