<template>
    <div>
        <div class="hero">
            <img src="../assets/qualification.jpg" alt="">
            <img src="../assets/qualification2.jpg" alt="" class="title">
        </div>
       
        <SelectButtons :buttons="buttons" @select="changeSeleced">

        </SelectButtons>

        <div class="content">
            <div v-if="selected == 'qualifications'">
                <img src="../assets/certificate1.jpg" class="customerImg" >
                <img src="../assets/certificate2.jpg" class="customerImg" >
                <img src="../assets/certificate3.jpg" class="customerImg" >
                <img src="../assets/certificate4.jpg" class="customerImg" >
                <img src="../assets/certificate5.jpg" class="customerImg" >
                <img src="../assets/certificate6.jpg" class="customerImg" >
                <img src="../assets/certificate7.jpg" class="customerImg double" >
                <img src="../assets/certificate8.jpg" class="customerImg" >
                <img src="../assets/certificate9.jpg" class="customerImg" >
                <img src="../assets/certificate10.jpg" class="customerImg" >
                <img src="../assets/certificate11.jpg" class="customerImg" >
                <img src="../assets/certificate12.jpg" class="customerImg" >
                <img src="../assets/certificate13.jpg" class="customerImg" >
                <img src="../assets/certificate14.jpg" class="customerImg" >
                <img src="../assets/certificate15.jpg" class="customerImg" >
                <img src="../assets/certificate16.jpg" class="customerImg" >
                <img src="../assets/certificate17.jpg" class="customerImg" >


            </div>
        </div>
        
    </div>
</template>

<script>
import SelectButtons from '@/components/SelectButtons.vue';
export default {
    components: {
        SelectButtons
    },
    data() {
        return {
            buttons: [{
                cn : "企业资质",
                en : "Qualifications",
                name : "qualifications"
            }],
            selected : "qualifications"
        }
    }
}
</script>

<style scoped>
.content{
    margin-top: 1em;
    text-align: center;
}


.scenarioImg,.hero img{
    width: 100%;
}

.customerImg{
    max-width: 300px;
    margin: 2em 2em;
}

.double{
    max-width: 75%;
}

.hero .title{
    width: 25em;
}

</style>