<template>
    <div>
        <div class="hero">
            <img src="../assets/product.jpg" alt="">
            <img src="../assets/product2.jpg" alt="" class="title">
        </div>
       
        <SearchConditions @searchBySize="searchBySize" 
        @searchById="searchById"
        v-if="!detail" 
        :defaultSelect="defaultSelected"
        :defaultId="defaultId" >

        </SearchConditions>

        <p v-if="error" class="error">{{ error }}</p>

        <div v-if="!detail">
            <div class="pallet"  v-for="pallet in showPallets" :key="pallet.id">
                <Pallet :pallet="pallet" @showDetails="showDetails"/>
            </div>  
        </div>

       

        <PalletDetails v-if="detail" :pallet="selectedPallet" @back="back"/>

        <div class="row" v-if="!detail">
            <button class="pagination-button-previous" @click="changePage(currentPage - 1)" :class="{disable : currentPage == 1}">{{ previous}}</button>
            <span v-for="(item,index) in Array(totalPage)" :key="(index)" >
                <button class="pagination-button" @click="changePage(index + 1)" :class="{select: currentPage == index + 1}">{{index + 1}}</button>
            </span>
            <button class="pagination-button-next" @click="changePage(currentPage + 1)" :class="{disable : currentPage == totalPage}">{{ next}}</button>
        </div>
        
    </div>
</template>

<script>
import SearchConditions from '@/components/SearchConditions.vue';
import Pallet from '@/components/Pallet.vue';
import PalletDetails from '@/components/PalletDetails.vue';
import axios from "axios";

export default {
    components: {
        Pallet,SearchConditions,PalletDetails
    },
    render(){
        this.totalPallets = []
    },
    data() {
        return {
            buttons: [{
                cn : "标准系列",
                en : "Standard Series",
                name : "standard"
            },
            {
                cn : "货架系列",
                en : "Shelf Series",
                name : "goods"
            },
            {
                cn : "超轻系列",
                en : "Ultralight Series",
                name : "light"
            }],
            totalPallets : [],
            showPallets : [],
            totalPage : 0,

            detail : false,
            componentKey : 0,
            currentPage : 1,

            defaultSelected : "",
            defaultId : "",
            error : "",
        }
    },
    methods: {
        showDetails(pallet){
            this.selectedPallet = pallet;
            this.detail = true;
        },
        changePage(pageNumber){
            if(pageNumber < 1 || pageNumber > this.totalPage) return;
            this.currentPage = pageNumber;

            const begin = ((this.currentPage - 1) * 8)
            const end = begin + 8
            this.showPallets = this.totalPallets.slice(begin,end)
        },
        searchBySize(option){
            var url
            if(this.$store.state.cn){
                url = "http://47.106.137.238:3001/pallet?lw=" + option + "&language=cn"
            }else{
                url = "http://47.106.137.238:3001/pallet?lw=" + option + "&language=en"
            }
            axios.get(url).then((response) => {

                this.error = ""

                this.totalPallets = response.data
                this.totalPage = Math.ceil(this.totalPallets.length / 8);

                const begin = ((this.currentPage - 1) * 8)
                const end = begin + 8
                this.showPallets = this.totalPallets.slice(begin,end)  

                this.defaultSelected = option
            })
        },
        searchById(id){
            var url
            if(this.$store.state.cn){
                url = "http://47.106.137.238:3001/pallet?id=" + id + "&language=cn"
            }else{
                url = "http://47.106.137.238:3001/pallet?id=" + id + "&language=en"
            }
            axios.get(url).then((response) => {
                
                if(response.data[0] != null){
                    this.error = ""

                    console.log(response.data)
                    this.selectedPallet = response.data[0]
                    this.detail = true

                    this.defaultId = id
                }else{
                    if(this.$store.state.cn){
                        this.error = "没有找到编号为"+id+"的产品"
                    }else{
                        this.error = "Couldn't Find Product With Number " + id
                    }
                    
                }
                
            })
        },
        back(){
            console.log(11333)
            this.detail = false;
        }
    },
    computed: {
        previous(){
            if(this.$store.state.cn){
                return "上一页"
            }
            return "Previous"
        },
        next(){
            if(this.$store.state.cn){
                return "下一页"
            }
            return "Next"
        },
        
    },
    
}
</script>

<style scoped>

    .select{
        background-color: rgb(11, 46, 99);
        color: white;
    }

    .pallet{
        width: 11em;
        height: 10em;
        margin: 1em;
        display: inline-block;
        cursor: pointer;
    }

    a{
	text-decoration: none;
    }

    .row{
        margin-top: 0.5em;
        text-align: center;
    }

    .pagination-button{
        padding: 7px;
        margin: 2px;
        font-size: 1em;
        cursor: pointer;
        border: 1px solid grey;
    }

    .pagination-button-previous,.pagination-button-next{
        padding: 7px;
        margin: 3px;
        margin-top: 5px;
        font-size: 0.9em;
        cursor: pointer;
        border: 1px solid grey;
        border-radius: 2px;
    }

    .hero img {
        width: 100%;
    }
    .hero .title{
        width: 25em;
    }

    .disable{
        cursor: not-allowed;
        background: lightgray;
        color: white;
    }

    .error{
        color: red;
    }

</style>