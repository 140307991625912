<template>
  <div class="mid">
    <div class="hero">
      
    <img src="../assets/hero.png" alt="">
  </div>

  <div class="content">
    <div class="index"  v-if="this.$store.state.cn">
        <h4>{{search}}</h4>
        
        <router-link :to="{ name: 'ProductSearch'}"><img :src="gotoProductSearch" alt=""></router-link>
        <router-link :to="{ name: 'ProductSearch'}"><img :src="gotoProductSearch2" alt=""></router-link>
        <router-link :to="{ name: 'Product'}"><img :src="gotoProduct" alt=""></router-link>
    </div>
    <div class="index" v-else>
         <h4>{{search}}</h4>
        
        <router-link :to="{ name: 'ProductSearchEN'}"><img :src="gotoProductSearch" alt=""></router-link>
        <router-link :to="{ name: 'ProductSearchEN'}"><img :src="gotoProductSearch2" alt=""></router-link>
        <router-link :to="{ name: 'ProductEN'}"><img :src="gotoProduct" alt=""></router-link>
    </div>


    <div class="introduction">
      <h4>{{intro}}</h4>
      <img src="http://www.qinghao.com/img/ab8.jpg" alt="">
      {{introContent}}
    </div>
    <div class="factory">
      <h4>{{factory}}</h4>
      <img src="http://img202.yun300.cn/img/xxxx.jpg?tenantId=221484&viewType=1&k=1625485789000">
    </div>
  </div>
  
</div>
  

</template>

<script>
export default {
  computed: {
    gotoProduct(){
        if(this.$store.state.cn){
            return require("../assets/gotoProductC.jpg")
        }else {
            return require("@/assets/gotoProduct.jpg")
        }
      },
      gotoProductSearch(){
          if(this.$store.state.cn){
              return require("../assets/gotoProductSearchC.jpg")
          }else {
              return require("@/assets/gotoProductSearch.jpg")
          }
      },
      gotoProductSearch2(){
        if(this.$store.state.cn){
            return require("../assets/gotoProductSearch2C.jpg")
        }else {
            return require("@/assets/gotoProductSearch2.jpg")
        }
      },
      search(){
        if(this.$store.state.cn){
            return "产品搜索"
        }else {
            return "Search Product"
        }
      },
      intro(){
          if(this.$store.state.cn){
            return "公司简介"
        }else {
            return "Company Profile"
        }
      },
      introContent(){
        if(this.$store.state.cn){
            return "上海庆豪塑料托盘制造有限公司位于上海市金山区漕泾镇共建路138号，专业从事生产塑料托盘。公司前身自1992年从意大利引进三万克注塑机生产塑料托盘，是国内较早的生产塑料托盘生产企业，公司已通过质量管理体系认证、职业健康安全管理体系认证、环境管理体系认证，也是中国物流与采购联合会托盘专业委员会常务理事单位。"
        }else {
            return "Founded in 1983, Shanghai Qinghao Plastic Pallet Manufacture Co., Ltd., a professional manufacturer of plastic pallets, is a member of China Plastic Pallet Association and one of the top 100 plastic product manufacturers in China. Moreover, the company takes the lead in passing the certifications of ISO and SGS. Our plastic pallets not only are popular in China, but also have been exported to more than ten countries and areas"
        }
      },
      factory(){
           if(this.$store.state.cn){
            return "工厂车间"
        }else {
            return "Factory"
        }
      }

  }
}
</script>

<style scoped>

.hero img{
  width: 100%;
}

h4{
  font: bolder;
  color: grey;
}

.content{
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.index{
  width: 15em;
}

.introduction{
  width: 20em;
  overflow: hidden;
}

.introduction img{
  margin: 1em;
  float: left;
  width: 50%;
}

.factory{
  width: 15em;
}

.display img{
  margin-top: 2em;
  min-height: 20px;
  width: 100%;
}



</style>