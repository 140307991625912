<template>
    <div>
       <label for="id" class="id" >
            {{searchId}}
            <input type="text" id="id" v-model="id">
            <button @click="searchById">{{search}}</button>
        </label>

        <label for="size" class="size">
            {{searchSize}}
            <select id="size" v-model="selected" @change="searchBySize">
                
                <option v-for="(option,index) in options" 
                :key="index" :disabled="option == '——请选择——' || option == '——Select——'" > 
                    {{ option }} 
                </option>

            </select> 
        </label>
    </div>
    
</template>

<script>
export default {
    props: {
        defaultSelect: {
            type: String,
            default: "——请选择——"
        },
        defaultId: {
            type: String,
            default: "——请选择——"
        },
    },
    created(){
        this.selected = this.defaultSelect;
        this.id = this.defaultId;
    },
    data() {
        return {
            optionsCn: ["——请选择——","1000*1000","1000*800","1100*800","1100*830","1100*900","1100*1100","1145*1145","1150*740","1180*880","1200*800","1200*1000","1200*1100","1200*1200","1250*1000","1250*850","1280*830","1260*840","1300*1000","1300*1100","1300*1300","1400*1100","1400*1200","1400*1400","1420*1185","1440*1130","1450*1150","1500*1185","1500*1500","1600*1400","1600*1600","其它"],
            optionsEn : ["——Select——","1000*1000","1100* 800","1100* 900","1100*1100","1145*1145","1180* 880","1200* 800","1200*1000","1200*1100","1200*1200","1250*1000","1250* 850","1280* 830","1260* 840","1300*1000","1300*1100","1300*1300","1400*1100","1400*1200","1400*1400","1420*1185","1440*1130","1450*1150","1500*1500","1600*1600","Others"],
            selected : "——请选择——",
            id : ""
        }
    },
    computed:{
        options(){
            if(this.$store.state.cn){
                return this.optionsCn
            }else{
                return this.optionsEn
            }
        },
        searchSize(){
            if(this.$store.state.cn){
                return "尺寸查询"
            }else{
                return "Search By Size"
            }
        },
        searchId(){
            if(this.$store.state.cn){
                return "编号查询"
            }else{
                return "Search By Number"
            }
        },
        search(){
            if(this.$store.state.cn){
                return "查询"
            }else{
                return "Search"
            }
        }
    },
    methods: {
        searchBySize() {
            this.id = ""
            this.$emit('searchBySize',this.selected)
        },
        searchById(){
            this.selected = "——请选择——"
            this.$emit('searchById',this.id)
        }
    },
}
</script>

<style scoped>
label{
    display: block;
    margin: 1em ;
}

.id,.size{
    padding: 10px;
}

.id > input{
    width: 5em;
}
</style>