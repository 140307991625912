<template>
    <div>
        
        <div class= "container">
      
            <div class="pallet-img">
                <img :src="pallet.img" alt="">
            </div>
            <div class="pallet-details">
                <p>{{key.model}} : {{pallet.model}}</p>
                <p>{{key.id}} : {{pallet.id}}</p>
                <p>{{key.lwh}} : {{pallet.lwh}}</p>
                <p>{{key.lw}} : {{pallet.lw}}</p>
                <p>{{key.type}} : {{pallet.type}}</p>
                <p>{{key.pattern}} : {{pallet.pattern}}</p>
                <p>{{key.dynamicLoad}} : {{pallet.dynamicLoad}}</p>
                <p>{{key.staticLoad}} : {{pallet.staticLoad}}</p>
                <p>{{key.shelf}} : {{pallet.shelf}}</p>
            </div>
        </div>

        <button class="back" @click="goBack">{{ back }}</button>
    </div>
 
</template>

<script>

export default {
    props: {
        pallet: {
            type: Object,
        },
    },
    data() {
        return {
            cn: {
                model : "型号",
                id : "产品编号",
                lwh : "长*宽*高",
                lw : "长*宽(mm)",
                type : "类型",
                pattern : "型式",
                dynamicLoad : "动载",
                staticLoad : "静载",
                shelf : "上货架"
            },
            en: {
                model : "Product Name",
                id : "Code",
                lwh : "L*W*H",
                lw : "L*W",
                type : "Style",
                pattern : "Type",
                dynamicLoad : "Dynamic Load",
                staticLoad : "Static Load",
                shelf : "On Shelf Weight"
            },
            
        }
    },
    computed: {
        key() {
            if(this.$store.state.cn){
                return this.cn
            }else{
                return this.en
            }
        },
        back() {
            if(this.$store.state.cn){
                return "返回"
            }else{
                return "Back"
            }
        }
    },
    methods: {
        goBack() {
            this.$emit('back')
        }
    },
    
}
</script>

<style scoped>
    .container{
        display: flex;
        margin-top: 1.5em;
        justify-content: center;
        flex-wrap: wrap;
        box-sizing: border-box;
    }
    .pallet-img > img{
       width: 100%;
    }

    .pallet-img{
        padding-right: 1.5em;
    }

    .pallet-details{
        padding-left: 1.5em;
    }
    .back{
        background: rgb(38, 38, 114);
        color: white;
        width: 30%;
        height: 30px;
        border-radius: 1em;
        display: block;
        margin: 2em auto;
        cursor: pointer;
    }

</style>