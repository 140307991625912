<template>
 
<!-- 导航 -->
<div>
	<div class="container">
        <div class="navbar" v-if="this.$store.state.cn">
				<li class="button"><router-link :to="{ name: 'Home'}">{{ home }}</router-link></li>
				<li class="button"><router-link :to="{ name: 'About',params:{id: '1'}}">{{ about }}</router-link></li>
				<li class="button"><router-link :to="{ name: 'Product'}">{{ products }}</router-link></li>
				<li class="button"><router-link :to="{ name: 'ProductSearch'}">{{ productsSearch }}</router-link></li>
				<li class="button"><router-link :to="{ name: 'Applications'}">{{ applications }}</router-link></li>
				<li class="button"><router-link :to="{ name: 'Qualifications'}">{{ qualifications }}</router-link></li>
				<li class="button"><router-link :to="{ name: 'Announcement'}">{{ announcement }}</router-link></li>
				<li class="button"><router-link :to="{ name: 'Contact'}">{{ contact }}</router-link></li>
        </div>
		<div class="navbar" v-else>
				<li class="button"><router-link :to="{ name: 'HomeEN'}">{{ home }}</router-link></li>
				<li class="button"><router-link :to="{ name: 'AboutEN',params:{id: '1'}}">{{ about }}</router-link></li>
				<li class="button"><router-link :to="{ name: 'ProductEN'}">{{ products }}</router-link></li>
				<li class="button"><router-link :to="{ name: 'ProductSearchEN'}">{{ productsSearch }}</router-link></li>
				<li class="button"><router-link :to="{ name: 'ApplicationsEN'}">{{ applications }}</router-link></li>
				<li class="button"><router-link :to="{ name: 'QualificationsEN'}">{{ qualifications }}</router-link></li>
				<li class="button"><router-link :to="{ name: 'AnnouncementEN'}">{{ announcement }}</router-link></li>
				<li class="button"><router-link :to="{ name: 'ContactEN'}">{{ contact }}</router-link></li>
        </div>
	</div>
</div>
</template>

<script>
export default {
	computed: {
		home() {
			return this.$store.state.cn ? "网站首页" : "Home" 
		},
		about() {
			return this.$store.state.cn ? "关于庆豪" : "About" 
		},
		products() {
			return this.$store.state.cn ? "产品分类" : "Products Categories" 
		},
		productsSearch() {
			return this.$store.state.cn ? "产品查询" : "Products Search" 
		},
		applications() {
			return this.$store.state.cn ? "产品应用" : "Applications" 
		},
		qualifications() {
			return this.$store.state.cn ? "企业资质" : "Qualifications" 
		},
		announcement() {
			return this.$store.state.cn ? "企业公告" : "Announcement" 
		},
		contact() {
			return this.$store.state.cn ? "联系我们" : "Contact" 
		}
	},
}
</script>

<style scoped>
.container{
    width: 100%;
    background-color: white;
	border-bottom: 1px solid blue;
}

.navbar{
	height: 100%;
    display: flex;
    flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
    
}
.button{
	padding: 13px 3px;
	
    list-style: none;
}

.button:not(:first-child){
    border-left: 0.5px solid lightgray;
}



a{
	text-decoration: none;
    font-weight: bold;
    color: #565656;
    font-size: 15px;
	margin: 1em;
}

.router-link-exact-active {
	border-bottom: 2px solid black;
	color: black;
}
</style>