<template>
  <div>
        <div class="hero">
            <img src="../assets/news.jpg" alt="">
            <img src="../assets/news2.jpg" alt="" class="title">
        </div>
        

        <div v-if="!detail">
            <div class="articles" v-for="article in articles" :key="article.id">
                <div class="article-container" @click="showDetail(article)">
                    <div class="article-title">{{article.title}}</div>
                    <div class="article-date">{{article.date}}</div>
                </div>
            </div>
        </div>

        <ArticleDetails v-if="detail" :article="selectedArticle" @back="back" class="article-details"></ArticleDetails>
        

        
  </div>
</template>

<script>
import axios from "axios";
import ArticleDetails from '@/components/ArticleDetails.vue';

export default {
    components: {
        ArticleDetails,
    },
    created () {
        var url
        if(this.$store.state.cn){
                url = "http://47.106.137.238:3001/articles?language=cn"
            }else{
                url = "http://47.106.137.238:3001/articles?language=en"
            }
        axios.get(url)
        .then(response => {
            this.articles = response.data
        })
        console.log(this.articles)
    },
    data() {
        return {
            articles: [],
            selectedArticle : "",
            detail : false
        }
    },
    computed: {
        sortedArticles() {
            return this.articles.sort((a, b) => b.id - a.id )
        }
    },
    methods: {
        showDetail(article) {
            this.selectedArticle = article
            this.detail = true
        },
        back(){
            this.detail = false
        }
    },
}
</script>

<style scoped>

.hero img{
    width: 100%;
}
.hero .title{
    width: 26em;
}

.article-container{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed darkgray;
    padding: 0.5em 2em;
    color: darkslategray;
    cursor: pointer;
}

.article-details{
    margin-top: 2em;
}



</style>